import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import Ey from "../Assets/DM Logo/Placement/EY.jpg"
// import NeedImage from '../Assets/needimage.png'
import Cotiviti from '../Assets/BAMSLOGO/Placement/cotiviti.jpg'
import Niimh from '../Assets/BAMSLOGO/Placement/Niimh.jpg'
import Purnava from '../Assets/BAMSLOGO/Placement/Purnava.jpg'
import Sg from '../Assets/BAMSLOGO/Placement/Sg.jpg'
import Yasoda from '../Assets/BAMSLOGO/Placement/Yasoda.jpg'
// import Thrnton from "../Assets/DM Logo/Placement/grant-thornton.png"
// import IBM from "../Assets/DM Logo/Placement/ibm.png"
// import KPMG from "../Assets/DM Logo/Placement/KPMG.jpg"
// import Societe from "../Assets/DM Logo/Placement/Societe Generale.jpg"
// import HCL from "../Assets/DM Logo/Placement/street hcl.jpg"
// import TCS from "../Assets/DM Logo/Placement/TCS.jpg"

function ALumnii() {
    const optionss = {
        items: 1,
        nav: true,
        navText: ["<div className='nav-btn prev-slide left-arrow arrow-button'><i class='arrow-left fa-solid fa-angle-left'></i></div>", "<div className='nav-btn next-slide'><i class='arrow-left fa-solid fa-angle-right'></i></div>"],
        rewind: true,
        autoplay: true,
        //   slideBy: 1,
        dots: true,
        dotsEach: true,
        dotData: true,
        responsive: {
            0: {
                items: 1
            },
            480: {
                items: 2
            },
            769: {
                items: 5
            }
        }
    };
    return (
        <div className="container mx-auto pt-10">
             <div className=" pt-16 pb-10">
            <div className="flex sm:block w-[100%]">
                <div className="w-[100%]   sm:block">
                    <div className="title sm:text-[24px] mdsm:text-[30px]  lg:pt-10   lg:text-[40px] pb-3 sm:px-4 text-secondary tracking-wider text-center">
                        Our Alumni work at
                    </div>
                    <div className="w-[100%] pb-10 pt-8 flex sm:p-4  sm:block lg:flex mdsm:block">
                        <div className="w-[100%] flex sm:w-[100%] lg:w-[100%] mdsm:w-[100%]">
                            <OwlCarousel className="owl-theme" {...optionss}>

                                <div className='item'>
                                    <div className='my-4 px-4'>
                                        <img src={Cotiviti} alt="TCS" />
                                    </div>
                                </div>

                                <div className='item '>
                                    <div className='my-4 px-4'>
                                        <img src={Niimh} alt="CRISIL" />
                                    </div>
                                </div>


                                <div className='item'>
                                    <div className='my-4 px-4'>
                                        <img src={Purnava} alt="Visuallite" />
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='my-4 px-4'>
                                        <img src={Sg} alt="yahoo" />
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='my-4 px-4'>
                                        <img src={Yasoda} alt="Web and craft" />
                                    </div>
                                </div>
                            </OwlCarousel>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
             </div> 
        </div>
    )
}

export default ALumnii