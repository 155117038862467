import React from "react";
import PagenotFoundImage from "../Components/Assets/404-page.webp"
function PageNotFound(){
    return(
        <div className="center bg-[#cbe2fe] h-[100vh] overflow-hidden">
            {/* <h1>404 Page</h1> */}
            <img src={PagenotFoundImage} alt="Page not found"/>
        </div>
    )
}

export default PageNotFound